<template>
  <div class="grzx">
    <div class="top">
      任务状态查询：
      <el-select @change="init(pageSize,0)" v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <!-- 我发布的 -->
    <div class="content">
      <el-table class="el-tables" border row-key="id" :data="tableData">
        <el-table-column prop="name" label="任务名称">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <div class="text_cent">{{ scope.row.name }}</div>
              <div slot="reference" class="name-wrapper">{{ scope.row.name }}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="任务状态">
          <template slot-scope="scope">
            <div v-if="scope.row.state==0">未启动</div>
            <div v-if="scope.row.state==1">已逾期</div>
            <div v-if="scope.row.state==2">加工中</div>
            <div v-if="scope.row.state==3">已完成</div>
            <div v-if="scope.row.state==4">已取消</div>
            <div v-if="scope.row.state==5">已违约</div>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="工期(截止)">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <div class="text_cent">{{scope.row.time}}天</div>
              <!-- 具体截止日期 -->
              <div class="text_cent">{{scope.row.endtime}}</div>
              <div slot="reference" class="name-wrapper">
                <div>{{scope.row.time}}天</div>
                <!-- 具体截止日期 -->
                <div>{{scope.row.endtime}}</div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="分配进度">
          <template slot-scope="scope">
            <span
              class="font"
              @click="jgjd(scope.row.id,scope.row.name,scope.row.jgFzrAndTaskId )"
            >{{scope.row.FPJD}}%</span>
          </template>
        </el-table-column>
        <el-table-column label="加工进度">
          <template slot-scope="scope">
            <span
              class="font"
              @click="jgjd(scope.row.id,scope.row.name,scope.row.jgFzrAndTaskId )"
            >{{scope.row.JGJD}}%</span>
          </template>
        </el-table-column>
        <el-table-column label="成果查看">
          <template slot-scope="scope">
            <!-- 未完成不能查看 -->
            <!-- <span v-if="scope.row.state!=3" @click="wwc" class="buttonss buttons1_1">查看</span> -->
            <span
              type="info"
              class="tuichubuttons1 buttons"
              plain
              disabled
              v-if="scope.row.state!=3"
            >查看</span>
            <!-- 已完成可查看 -->
            <span
              v-else
              @click="see(scope.row.id,scope.row.tasktype,scope.row.jgFzrAndTaskId)"
              class="buttons buttons1_2"
            >查看</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <span
              @click="deleted(scope.row.id,scope.row.tasktype,scope.row.state)"
              class="buttons buttons2"
            >删除</span>
            <span
              v-if="scope.row.state==0"
              @click="quxiao(scope.row.id,scope.row.tasktype,scope.row.state,scope.row.jgFzrAndTaskId,scope.row.totalmoney)"
              class="buttons buttons3"
            >取消</span>
            <span v-else type="info" class="tuichubuttons1 buttons margin_left5px" plain disabled>取消</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="prev">
        <!-- :hide-on-single-page="true" -->
        <!-- @prev-click="currentPage"
        @next-click="currentPage" -->
        <el-pagination
          @current-change="currentPage"
          background
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <!-- <table>
      <tr>
        <th>任务名称</th>
        <th>任务状态</th>
        <th>工期(截止)</th>
        <th>分配进度</th>
        <th>加工进度</th>
        <th>成果查看</th>
        <th>操作</th>
      </tr>
      <tr v-for="item in tableData" :key="item.id">
        <td>{{item.id}}</td>
        <td>{{item.name}}</td>
        <td>{{item.state}}</td>
        <td>{{item.state}}</td>
         <td>{{item.state}}</td>
        <td>
          <span class="buttons buttons1_1">查看</span>
          <span class="buttons buttons1_2">查看</span>
        </td>
        <td>
          <span class="buttons buttons2">删除</span>
          <span class="buttons buttons3">取消</span>
        </td>
      </tr>
    </table>-->
    <div class="zrwfp" v-if="jindu">
      <div class="zrwfp_top">
        <div class="zrwfp_title">{{rwmc}} —— 子任务分配表</div>
        <div class="zrwfp_fzr">负责人：{{fzrpeople}}</div>
      </div>
      <el-table
        border
        row-key="id"
        class="el-table"
        :data="tableDatas"
        :header-cell-style="{
          background: '#FFFFFF',
          color: '#000000',
        }"
      >
        <el-table-column prop="childTaskName" label="子任务名称">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <div class="text_cent">{{ scope.row.childTaskName }}</div>
              <div slot="reference" class="name-wrapper">{{ scope.row.childTaskName }}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <div v-if="scope.row.status==0">未启动</div>
            <div v-if="scope.row.status==1">未启动</div>
            <div v-if="scope.row.status==2">加工中</div>
            <div v-if="scope.row.status==3">校对中</div>
            <div v-if="scope.row.status==4">审核中</div>
            <div v-if="scope.row.status==5">已完成</div>
            <div v-if="scope.row.status==6">已逾期</div>
            <div v-if="scope.row.status==7">已违约</div>
          </template>
        </el-table-column>
        <el-table-column prop="drawTime" label="领取时间"></el-table-column>
      </el-table>
      <!-- <div class="prev">
        <el-pagination background layout="prev, pager, next" :total="1000"></el-pagination>
      </div>-->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { post, get } from "@/services/http.js";
import { dateType, YearMMdd } from "@/js/common/index.js";
import {
  DELETE,
  QUERY,
  UPDATES,
  UPDATE,
  QUERYED,
  INSERT
} from "@/services/dao.js";
export default {
  data() {
    return {
      options: [
        {
          value: -1,
          label: "全部"
        },
        {
          value: 0,
          label: "未启动"
        },
        {
          value: 1,
          label: "已逾期"
        },
        {
          value: 2,
          label: "加工中"
        },
        {
          value: 3,
          label: "已完成"
        },
        {
          value: 4,
          label: "已取消"
        },
        {
          value: 5,
          label: "已违约"
        }
      ],
      value: -1,
      tableData: [], //我发布的
      tableDatas: [], //子任务
      jindu: false, //是否显示子任务
      pageSize: 5, //条数
      total: 0, //总条数
      pageNum: 0, ////当前页数
      fzrpeople: "", //负责人
      rwmc: "" //任务名称
    };
  },
  created() {
    this.init(this.pageSize, 0);
  },
  methods: {
    // 查看
    see(id, type, fzrid) {
      this.$router.push({
        path: "/Achievement",
        query: {
          id: id,
          type: type, //任务负责人
          fzrid: fzrid, //负责人ID
          state: 0 //0:发布者，1：负责人
        }
      });
    },
    ///取消
    async quxiao(id, type, state, jgFzrAndTaskId, totalmoney) {
      let info = null;
      if (state == 0) {
        ///取消
        ////修改状态为已取消
        if (type == 0) {
          //////纸质化产品
          info = await UPDATE(
            "post",
            "",
            "update_jgPeper(where: {id: {_eq: " +
              id +
              "},jgFzrAndTaskId : {_eq: " +
              jgFzrAndTaskId +
              "}}, _set: {status : 4}) { affected_rows  }"
          );
          if (info.data.update_jgPeper.affected_rows == 1) {
            this.$message({
              message: "已取消！",
              type: "success"
            });
            this.init(this.pageSize, this.pageNum); //刷新
          } else {
            this.$message.error("取消失败！");
          }
        } else if (type == 1) {
          ////数字化产品加工
          info = await UPDATE(
            "post",
            "",
            "update_jgDigitalPro(where: {id: {_eq: " +
              id +
              "},jgFzrAndTaskId : {_eq: " +
              jgFzrAndTaskId +
              "}}, _set: {status : 4}) { affected_rows  }"
          );
          if (info.data.update_jgDigitalPro.affected_rows == 1) {
            this.$message({
              message: "已取消！",
              type: "success"
            });
            this.init(this.pageSize, this.pageNum); //刷新
          } else {
            this.$message.error("取消失败！");
          }
        } else if (type == 2) {
          /////词条
          info = await UPDATE(
            "post",
            "",
            "update_jgEntryPro(where: {id: {_eq: " +
              id +
              "},jgFzrAndTaskId : {_eq: " +
              jgFzrAndTaskId +
              "}}, _set: {status : 4}) { affected_rows  }"
          );
          if (info.data.update_jgEntryPro.affected_rows == 1) {
            ////修改个人账户
            let fbz = await QUERYED(
              "POST",
              "",
              "User(where: {id  : {_eq: " +
                this.$store.state.userinfo.id +
                "}}) {Money zsjg_prepay }"
            );
            let zsjg_prepay = 0;
            let Money = 0;
            zsjg_prepay =
              Number(fbz.data.User[0].zsjg_prepay) - Number(totalmoney);
            Money = Number(fbz.data.User[0].Money) + Number(totalmoney);
            let fbz_moeny = await UPDATE(
              "post",
              "",
              "update_User(where: {id: {_eq: " +
                this.$store.state.userinfo.id +
                "}}, _set: {Money : " +
                Money +
                ",zsjg_prepay : " +
                zsjg_prepay +
                "}) { affected_rows  }"
            );
            if (fbz_moeny.data.update_User.affected_rows == 1) {
              this.$message({
                message: "已取消！",
                type: "success"
              });
              this.init(this.pageSize, this.pageNum); //刷新
            }
          } else {
            this.$message.error("取消失败！");
          }
        }
      } else {
        this.$message({
          message: "任务已启动，不可以取消！",
          type: "warning"
        });
      }
    },
    //下一页
    nextPage(val) {
      this.pageNum = val - 1;
      this.init(this.pageSize, this.pageSize * this.pageNum);
    },
    //当前页
    currentPage(val) {
      this.pageNum = val - 1;
      this.init(this.pageSize, this.pageSize * this.pageNum);
    },
    // 未完成不可查看
    wwc() {
      this.$message({
        message: "任务未完成不可查看!",
        type: "warning"
      });
    },
    // 初始化
    async init(limit, offset) {
      this.tableData = [];
      let info = null;
      if (this.value == -1) {
        ///查询全部
        info = await QUERYED(
          "post",
          "",
          "JGSearch(limit: " +
            limit +
            ", offset: " +
            offset +
            ", where: {FB_delete: {_neq: 1},userid : {_eq:" +
            this.$store.state.userinfo.id +
            " }}){taskname status jgFzrAndTaskId  totaldays endTime id totalmoney fzr_totalmoney  tasktype issueTime } JGSearch_aggregate (where: {userid : {_eq:" +
            this.$store.state.userinfo.id +
            " }}){     aggregate {       count     }   }"
        );
      } else {
        ///分类查询
        info = await QUERYED(
          "post",
          "",
          "JGSearch(limit: " +
            limit +
            ", offset: " +
            offset +
            ", where: {FB_delete: {_neq: 1},userid : {_eq:" +
            this.$store.state.userinfo.id +
            " },status : {_eq:" +
            this.value +
            " }}){taskname status jgFzrAndTaskId  totaldays endTime id totalmoney fzr_totalmoney  tasktype issueTime } JGSearch_aggregate (where: {userid : {_eq:" +
            this.$store.state.userinfo.id +
            " }}){     aggregate {       count     }   }"
        );
      }
      this.total = info.data.JGSearch_aggregate.aggregate.count; //
      for (let j = 0; j < info.data.JGSearch.length; j++) {
        let FPJD = null; //分配
        let JGJD = null; //加工
        if (info.data.JGSearch[j].jgFzrAndTaskId) {
          //纸质化加工
          let zrw = await QUERYED(
            "post",
            "",
            "jgChildTask_aggregate (where: {taskId  : {_eq:" +
              info.data.JGSearch[j].id +
              "},fzrId   : {_eq:" +
              info.data.JGSearch[j].jgFzrAndTaskId +
              "}}){     aggregate {       count     }   }"
          );
          let fpwc = await QUERYED(
            "post",
            "",
            "jgChildTask_aggregate (where: {fzrId   : {_eq:" +
              info.data.JGSearch[j].jgFzrAndTaskId +
              "},taskId  : {_eq:" +
              info.data.JGSearch[j].id +
              ' },_not: {status: {_eq: "0"}}}){     aggregate {       count     }   } '
          );
          if (
            fpwc.data.jgChildTask_aggregate.aggregate.count == 0 &&
            zrw.data.jgChildTask_aggregate.aggregate.count == 0
          ) {
            FPJD = 0;
          } else {
            FPJD =
              (Number(fpwc.data.jgChildTask_aggregate.aggregate.count) /
                Number(zrw.data.jgChildTask_aggregate.aggregate.count)) *
              100;
          }
          if (info.data.JGSearch[j].tasktype == 0) {
            //纸质化加工
            let jgwc = await QUERYED(
              "post",
              "",
              "jgChildTask_aggregate (where: {taskId  : {_eq:" +
                info.data.JGSearch[j].id +
                ' },status   : {_eq:"5"},fzrId   : {_eq:' +
                info.data.JGSearch[j].jgFzrAndTaskId +
                "}}){     aggregate {       count     }   } "
            );
            if (
              jgwc.data.jgChildTask_aggregate.aggregate.count == 0 &&
              zrw.data.jgChildTask_aggregate.aggregate.count == 0
            ) {
              JGJD = 0;
            } else {
              // 加工进度
              JGJD =
                (Number(jgwc.data.jgChildTask_aggregate.aggregate.count) /
                  Number(zrw.data.jgChildTask_aggregate.aggregate.count)) *
                100;
            }
          } 
          // else if (info.data.JGSearch[j].tasktype == 1) {
          //   //数字化
          //   await get("/common/SzcpProgressQuery", {
          //     taskId: info.data.JGSearch[j].id
          //   })
          //     .then(e => {
          //       JGJD = e.data; //课题二加工
          //       console.log("数字成功", JGJD);
          //     })
          //     .catch(function(error) {
          //       console.log("错误" + error);
          //     });
          // } else if (info.data.JGSearch[j].tasktype == 2) {
          //   //词条
          //   await post("/common/EntryProgressQuery", {
          //     taskId: info.data.JGSearch[j].id,
          //     type: 0
          //   })
          //     .then(e => {
          //       JGJD = e.data; //课题三加工
          //       console.log("成功成功", JGJD);
          //     })
          //     .catch(function(error) {
          //       console.log("错误" + error);
          //     });
          // }
        } else {
          FPJD = 0;
          JGJD = 0;
        }
        // 取整
        FPJD = Math.round(FPJD);
        JGJD = Math.round(JGJD);
        this.tableData.push({
          id: info.data.JGSearch[j].id,
          state: info.data.JGSearch[j].status, //状态
          time: info.data.JGSearch[j].totaldays, //日期
          name: info.data.JGSearch[j].taskname, //任务名称
          tasktype: info.data.JGSearch[j].tasktype, //分类
          totalmoney: info.data.JGSearch[j].totalmoney, //总酬劳
          jgFzrAndTaskId: info.data.JGSearch[j].jgFzrAndTaskId, //负责人ID
          endtime:
            info.data.JGSearch[j].endTime == null
              ? ""
              : "(" + YearMMdd(info.data.JGSearch[j].endTime) + ")", //到期时间
          FPJD: FPJD == "" ? 0 : FPJD,
          JGJD: JGJD == "" ? 0 : JGJD
        });
      }
      
    },
    // 查看子任务
    async jgjd(id, name, fzrid) {
      console.log("负责人ID", fzrid);
      // this.jindu = !this.jindu;
      this.jindu = true; //
      this.tableDatas = []; //清空
      if (fzrid) {
        let info = await QUERYED(
          "post",
          "",
          "jgChildTask(where: {fzrId   : {_eq:" +
            fzrid +
            "},taskId  : {_eq:" +
            id +
            " }}){id taskId taskName fzrName status  drawTime childTaskName } jgChildTask_aggregate (where: {fzrId   : {_eq:" +
            fzrid +
            "},taskId : {_eq:" +
            id +
            " }}){     aggregate {       count     }   }"
        );
        for (let j = 0; j < info.data.jgChildTask.length; j++) {
          this.tableDatas.push({
            id: info.data.jgChildTask[j].id,
            taskName: info.data.jgChildTask[j].taskName,
            fzrName: info.data.jgChildTask[j].fzrName,
            status: info.data.jgChildTask[j].status,
            drawTime:
              info.data.jgChildTask[j].drawTime == null
                ? ""
                : YearMMdd(Number(info.data.jgChildTask[j].drawTime)),
            childTaskName: info.data.jgChildTask[j].childTaskName
          });
        }
      }
      // if (info.data.jgChildTask[0].fzrName)
      this.rwmc = name; //任务名称
      if (fzrid == undefined) {
        this.fzrpeople = "暂未确定负责人";
      } else {
        // 根据负责人ID查找负责人名称
        let datas = await QUERYED(
          "post",
          "",
          "User(where: {id  : {_eq:" + fzrid + " }}){name } "
        );
        this.fzrpeople = datas.data.User[0].name; //负责人
      }
      // }
    },
    ///删除
    async deleted(id, type, state) {
      if (state == 2) {
        this.$message({
          message: "该任务状态为正在加工中方不可删除！",
          type: "warning"
        });
      } else if (state == 1) {
        this.$message({
          message: "该任务状态为已逾期不可删除！",
          type: "warning"
        });
      } else if (state == 0) {
        this.$message({
          message: "该任务状态为未启动不可删除！",
          type: "warning"
        });
      } else {
        let info = null;
        if (type == 0) {
          //////纸质化产品
          info = await UPDATE(
            "post",
            "",
            "update_jgPeper(where: {id: {_eq: " +
              id +
              "}, tasktype : {_eq: " +
              type +
              "}}, _set: {FB_delete  : 1}) { affected_rows  }"
          );
          // info = await DELETE(
          //   "post",
          //   "",
          //   "delete_jgPeper(where: {id : {_eq: " +
          //     id +
          //     "}, tasktype : {_eq: " +
          //     type +
          //     "}}) {   affected_rows  }"
          // );
          if (info.data.update_jgPeper.affected_rows == 1) {
            this.$message({
              message: "删除成功！",
              type: "success"
            });
            this.init(this.pageSize, this.pageSize * this.pageNum); //刷新
          } else {
            this.$message.error("删除失败！");
          }
        } else if (type == 1) {
          ////数字化产品加工
          // info = await DELETE(
          //   "post",
          //   "",
          //   "delete_jgDigitalPro(where: {id : {_eq: " +
          //     id +
          //     "}, tasktype : {_eq: " +
          //     type +
          //     "}}) {   affected_rows  }"
          // );
          info = await UPDATE(
            "post",
            "",
            "update_jgDigitalPro(where: {id: {_eq: " +
              id +
              "}, tasktype : {_eq: " +
              type +
              "}}, _set: {FB_delete  : 1}) { affected_rows  }"
          );
          if (info.data.update_jgDigitalPro.affected_rows == 1) {
            this.$message({
              message: "删除成功！",
              type: "success"
            });
            this.init(this.pageSize, this.pageSize * this.pageNum); //刷新
          } else {
            this.$message.error("删除失败！");
          }
        } else if (type == 2) {
          ///// 词条
          // info = await DELETE(
          //   "post",
          //   "",
          //   "delete_jgEntryPro(where: {id : {_eq: " +
          //     id +
          //     "}, tasktype : {_eq: " +
          //     type +
          //     "}}) {   affected_rows  }"
          // );
          info = await UPDATE(
            "post",
            "",
            "update_jgEntryPro(where: {id: {_eq: " +
              id +
              "}, tasktype : {_eq: " +
              type +
              "}}, _set: {FB_delete  : 1}) { affected_rows  }"
          );
          if (info.data.update_jgEntryPro.affected_rows == 1) {
            this.$message({
              message: "删除成功！",
              type: "success"
            });
            this.init(this.pageSize, this.pageSize * this.pageNum); //刷新
          } else {
            this.$message.error("删除失败！");
          }
        }
      }
    }
  }
};
</script>
<style scoped>
/* table{
  width: 100%;
  text-align: center;
  border: 1px solid #000000;
}
table tr th{
  height: 50px;
  border-bottom: 1px solid #000000;
  border: 1px solid #000000;
  line-height: 50px;
} */
.el-tables {
  width: 100%;
  /* border: 1px solid #000000; */
  margin-top: 30px;
}
/* ::v-deep  .el-table .cell {
  white-space: nowrap !important;
  text-align: center;
} */
.el-select {
  /* margin-top: 20px; */
}
.top {
  margin-top: 30px;
}
.font {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-decoration: underline;
  color: #3e94f7;
}
.top {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
}
::v-deep .el-table .warning-row {
  background: rgb(221, 211, 191);
}
::v-deep .el-table .success-row {
  background: #8ab673;
}
.el-tables {
  /* border-right: 1px solid #000000; */
}
/* ::v-deep table {
  border-left: 1px solid #000000;
}
::v-deep thead {
  background-color: #b92424;
} */
/* ::v-deep table{
  overflow: auto;
} */
/* ::v-deep table thead tr th {
  border-right: 1px solid #000000;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
} */
/* .grzx>>> thead tr .el-table_1_column_1 {
  border-left:none;
}
 .grzx>>>  .el-table__row .el-table_1_column_1  {
  border-left:none;
} */
/* ::v-deep tbody tr td {
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
::v-deep .el-table th.is-leaf {
  border-bottom: 1px solid #000000;
} */
.el-table::before {
  z-index: auto;
  /* background-color: red; */
}
.buttons {
  width: 55px;
  height: 27px;
  line-height: 27px;
  display: inline-block;
  border-radius: 5px;
  text-align: center;
}
.buttons2 {
  background-color: #f8d5d8;
  color: #ba0f2e;
  margin-left: 5px;
  border: 1px solid #f0a7ad;
}
.buttons3 {
  background-color: #e5e5e5;
  color: #000502;
  margin-left: 5px;
  border: 1px solid #c0c0c0;
}
.buttons1_1 {
  background-color: #e5e5e5;
  color: #000502;
  border: 1px solid #c0c0c0;
}
.buttons1_2 {
  background-color: #3e94f7;
  color: #ffffff;
  border: 1px solid #3e94f7;
}
.content {
  width: 760px;
  padding-right: 12px;
  /* border: 1px solid red; */
}
.prev {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}
.zrwfp {
  width: 760px;
  /* border: 1px solid red; */
  margin: auto;
  margin-top: 50px;
}
.zrwfp_top {
  width: 758px;
  height: 70px;
  background-color: #ffffff;
  border-right: 1px solid #e4e7ed;
  border-left: 1px solid #e4e7ed;
  border-top: 1px solid #e4e7ed;
  padding: 20px 0px 0px 0px;
}
.zrwfp_top > div {
  width: 100%;
  text-align: center;
  /* border: 1px solid red; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 27px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  /* padding: 0px 10px 0px 12px; */
}
.zrwfp_title {
  font-weight: bold;
  color: #000000;
}
.zrwfp_fzr {
  font-weight: 400;
  color: #3e94f7;
}
.red {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c10015;
  line-height: 52px;
  text-align: center;
}
.grzx >>> .el-table__header {
  /* border: 2px solid red; */
  width: 100% !important;
}
.grzx >>> .el-table__body {
  width: 100% !important;
}
/* 查看按钮 */
.buttonss {
  /* margin-left: 5px; */
  width: 57px;
  height: 30px;
  /* line-height: 3px; */
  text-align: center;
  /* position: relative;
  top: 5px; */
  background-color: #1c9beb;
  color: #000502;
  /* margin-left: 5px; */
  border: 1px solid #a5d1fa;
  /* margin-left: 5px; */
}
.quixiao {
  width: 57px;
  text-align: center;
  height: 32px;
  line-height: 8px;
  display: inline-block;
  margin-left: 5px;
}
::v-deep .quixiao > span {
  position: relative;
  /* left: -6px; */
}
::v-deep .buttonss > span {
  /* position: relative;
  left: -6px;
  top: -5px; */
  /* font-size: 15px; */
}
.text_cent {
  text-align: center;
  /* border: 1px solid red; */
}
.border_jiny {
  border: 1px solid #a5d1fa;
}
.tuichubuttons1 {
  background-color: #f5f4f4;
  color: #6e6e6e;
  border: 1px solid #afafaf;
  cursor: no-drop !important;
}
.margin_left5px {
  margin-left: 5px;
}
</style>